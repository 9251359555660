import { JoinSchoolCommand } from "commands/users/JoinSchoolCommand";
import { GetTeacherSubscriptionResult } from "results/frankenstories/teachers/GetTeacherSubscriptionResult";
import { GetTeacherSubscriptionsResult } from "results/frankenstories/teachers/GetTeacherSubscriptionsResult";
import { LoginResult } from "results/account/LoginResult";
import { useApiService } from "services/ApiService";
import { GlobalDataResult } from "results/account/GlobalDataResult";

export const TeachersApiService = () => {
    const { get, post } = useApiService({ controller: "/teachers" });

    return {
        completeTeacherIntroduction: () => post<LoginResult>("/complete-teacher-introduction"),
        joinSchool: (command: JoinSchoolCommand) => post<GlobalDataResult>("/join-school", command),
        leaveSchool: () => post<GlobalDataResult>("/leave-school"),
        getTeacherSubscription: (id: number) => get<GetTeacherSubscriptionResult>(`/subscription/${id}`),
        getTeacherSubscriptions: () => get<GetTeacherSubscriptionsResult>("/subscriptions"),
        refreshSubscription: () => post("/refresh-subscription"),
    };
};
