import { ReactNode } from "react";

export const PageHeader = (props: {
    title?: string;
    description?: string;
    action?: ReactNode;
    icon: ReactNode;
    hideDescription?: boolean;
}) => {
    const { title, description, action, icon, hideDescription } = props;

    return (
        <div className="page-header">
            {icon && <div className="page-header__icon">{icon}</div>}

            <h1 className="page-header__title h2 text-break">
                {title ?? (
                    <div className="placeholder-glow">
                        <span className="col-9 py-2 placeholder"></span>
                    </div>
                )}
            </h1>

            {!hideDescription && (
                <div className="page-header__description text-break fs-5 fw-light">
                    {description ? description : <span className="col-9 py-2 placeholder"></span>}
                </div>
            )}

            {action && <div className="page-header__actions d-print-none">{action}</div>}
        </div>
    );
};
