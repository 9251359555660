import { IdCommand } from "commands/IdCommand";
import { AssignGameAsHomeworkCommand } from "commands/frankenstories/AssignGameAsHomeworkCommand";
import { AssignGameToClassCommand } from "commands/frankenstories/AssignGameToClassCommand";
import { CreateTemplateCommand } from "commands/frankenstories/CreateTemplateCommand";
import { UpdateTemplateCommand } from "commands/frankenstories/UpdateTemplateCommand";
import { sessionId } from "contexts/GlobalContext";
import { CodeResult } from "results/CodeResult";
import { CommandResult } from "results/CommandResult";
import { CompletedGamePlayerStateResult } from "results/frankenstories/CompletedGamePlayerStateResult";
import { CreateTemplateResult } from "results/frankenstories/CreateTemplateResult";
import { GenerateRandomStimulusResult } from "results/frankenstories/GenerateRandomStimulusResult";
import { GetAssignTemplateResult } from "results/frankenstories/GetAssignTemplateResult";
import { GetCreateTemplateResult } from "results/frankenstories/GetCreateTemplateResult";
import { GetGalleryResult } from "results/frankenstories/GetGalleryResult";
import { GetGameSettingsResult } from "results/frankenstories/GetGameSettingsResult";
import { GetGamesHallOfFameResult } from "results/frankenstories/GetGamesHallOfFameResult";
import { GetGlobalSettingsResult } from "results/frankenstories/GetGlobalSettingsResult";
import { GetHallOfFamePreviewResult } from "results/frankenstories/GetHallOfFamePreviewResult";
import { GetHallOfFameResult } from "results/frankenstories/GetHallOfFameResult";
import { GetMultiModerateGamesResult } from "results/frankenstories/GetMultiModerateGamesResult";
import { GetMyGamesResult } from "results/frankenstories/GetMyGamesResult";
import { GetHistoryResult } from "results/frankenstories/GetHistoryResult";
import { GetReportsResult } from "results/frankenstories/GetReportsResult";
import { GetTemplateResult } from "results/frankenstories/GetTemplateResult";
import { GetTemplateSettingsResult } from "results/frankenstories/GetTemplateSettingsResult";
import { GetTemplatesResult } from "results/frankenstories/GetTemplatesResult";
import { UploadImageResult } from "results/frankenstories/UploadImageResult";
import { ImageListResult } from "results/images/ImageListResult";
import { useApiService } from "services/ApiService";
import { GetStudentHomeworkResult } from "results/frankenstories/GetStudentHomeworkResult";
import { GetTeacherHomeworkResult } from "results/frankenstories/GetTeacherHomeworkResult";
import { GetCreateGameResult } from "results/frankenstories/GetCreateGameResult";

export const GamesApiService = () => {
    const { get, post, getHeaders, getUrl } = useApiService({ controller: "/games" });

    return {
        uploadImage: async (file: File) => {
            const headers = getHeaders(false);

            const formData = new FormData();

            formData.append("file", file);

            const x = await fetch(getUrl("/upload-image"), {
                method: "POST",
                body: formData,
                headers,
            });
            return await (x.json() as Promise<CommandResult<UploadImageResult>>);
        },
        getHistory: () => get<GetHistoryResult>(`/history/${sessionId}`),
        getPromptImages: () => get<ImageListResult>("/prompt-images"),
        getHallOfFame: () => get<GetHallOfFameResult>("/hall-of-fame"),
        getHallOfFamePreview: () => get<GetHallOfFamePreviewResult>("/hall-of-fame-preview"),
        getGlobalSettings: () => get<GetGlobalSettingsResult>("/data"),
        getCompletedPlayerState: (code: string) =>
            get<CompletedGamePlayerStateResult>(`/completed-player-state/${code}/${sessionId}`),
        getRandom: (categoryId?: number, themeId?: number) =>
            get<GenerateRandomStimulusResult>(`/random?themeId=${themeId ?? ""}&categoryId=${categoryId ?? ""}`),
        getGameSettings: (code: string) => get<GetGameSettingsResult>(`/settings/${code}/${sessionId}`),
        getTemplateSettings: (id: number) => get<GetTemplateSettingsResult>(`/template/${id}/settings`),
        updateTemplate: (command: UpdateTemplateCommand) => post("/update-template", command),
        getCreateTemplate: (templateId: string | null) => {
            const suffix = templateId ? `?templateId=${templateId}` : "";
            return get<GetCreateTemplateResult>(`/create-template${suffix}`);
        },
        createTemplate: (command: CreateTemplateCommand) => post<CreateTemplateResult>("/create-template", command),
        getCreateGame: () => get<GetCreateGameResult>("/create-game"),
        deleteTemplate: (id: number) => post("/delete-template", { id }),
        getMyGames: () => get<GetMyGamesResult>(`/my-games/${sessionId}`),
        getGallery: (id: number) => get<GetGalleryResult>(`/gallery/${id}`),
        getTemplate: (id: number) => get<GetTemplateResult>(`/template/${id}`),
        getAssignTemplate: (id: number) => get<GetAssignTemplateResult>(`/template/${id}/assign`),
        assignTemplateToClass: (command: AssignGameToClassCommand) => post("/assign-to-class", command),
        assignTemplateAsHomework: (command: AssignGameAsHomeworkCommand) => post("/assign-as-homework", command),
        createQuickGameFromTemplate: (command: IdCommand) =>
            post<CodeResult>("/create-quick-game-from-template", command),
        getMultiModerateGames: () => get<GetMultiModerateGamesResult>("/multi-moderate"),
        getTemplates: () => get<GetTemplatesResult>("/templates"),
        getReports: () => get<GetReportsResult>("/reports"),
        getGamesHallOfFame: () => get<GetGamesHallOfFameResult>(`/hall-of-fame/${sessionId}`),
        getStudentHomework: () => get<GetStudentHomeworkResult>("/student-homework"),
        getTeacherHomework: () => get<GetTeacherHomeworkResult>("/teacher-homework"),
    };
};
