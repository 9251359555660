import { CodeCommand } from "commands/CodeCommand";
import { EmailCommand } from "commands/EmailCommand";
import { GuidCommand } from "commands/GuidCommand";
import { AcceptInvitationCommand } from "commands/users/AcceptInvitationCommand";
import { ChangePasswordCommand } from "commands/account/ChangePasswordCommand";
import { EmailLoginCommand } from "commands/account/EmailLoginCommand";
import { RecoverPasswordCommand } from "commands/account/RecoverPasswordCommand";
import { RegisterEmailCommand } from "commands/account/RegisterEmailCommand";
import { RegisterStudentCommand } from "commands/account/RegisterStudentCommand";
import { sessionId } from "contexts/GlobalContext";
import { GetInvitationResult } from "results/users/GetInvitationResult";
import { GlobalDataResult } from "results/account/GlobalDataResult";
import { useApiService } from "services/ApiService";
import { TeacherLoginResult } from "results/account/TeacherLoginResult";
import { ApproveUserResult } from "results/account/ApproveUserResult";
import { CodeLoginResult } from "results/account/CodeLoginResult";
import { AcceptInvitationResult } from "results/account/AcceptInvitationResult";
import { RecoverPasswordResult } from "results/account/RecoverPasswordResult";
import { RegisterStudentResult } from "results/account/RegisterStudentResult";
import { SuccessResult } from "results/SuccessResult";

export const AccountApiService = () => {
    const { get, post } = useApiService({ controller: "/account" });

    return {
        loginEmail: (command: EmailLoginCommand) => post<TeacherLoginResult>("/login-email", { ...command, sessionId }),
        loginCode: (command: CodeCommand) => post<CodeLoginResult>("/login-code", { ...command, sessionId }),
        registerUser: (command: RegisterEmailCommand) => post("/register-teacher", command),
        registerStudent: (command: RegisterStudentCommand) => post<RegisterStudentResult>("/register-student", command),
        changePassword: (command: ChangePasswordCommand) => post("/change-password", command),
        approveUser: (command: GuidCommand) => post<ApproveUserResult>("/approve", command),
        forgotPassword: (command: EmailCommand) => post("/forgot-password", command),
        getRecoverPassword: (hash: string) => get<SuccessResult>(`/recover-password/${hash}`),
        recoverPassword: (command: RecoverPasswordCommand) => post<RecoverPasswordResult>("/recover-password", command),
        getInvitation: (hash: string) => get<GetInvitationResult>(`/invitation/${hash}`),
        getGlobalUserData: () => get<GlobalDataResult>("/global-data"),
        acceptInvitation: (command: AcceptInvitationCommand) =>
            post<AcceptInvitationResult>("/accept-invitation", command),
    };
};
