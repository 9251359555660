import * as signalR from "@microsoft/signalr";

export const Constants = {
    animationDuration: 180,
    webUrl: process.env.REACT_APP_WEB_URL ?? "",
    schoolsUrl: process.env.REACT_APP_SCHOOLS_URL ?? "",
    gtm: process.env.REACT_APP_GTM_FRANKENSTORIES ?? "",
    signalRLogLevel: signalR.LogLevel.Error,
    lessonTeachingWithFrankenstories: "https://writelike.org/lessons/teaching-with-frankenstories",
    instagramUrl: "https://www.instagram.com/frankenstories_game/",
};
