import { NavLink } from "react-router-dom";
import { RouteList } from "Routes";
import { Constants } from "utils/Constants";

const FooterLink = (props: { name: string; url: string }) => {
    const { name, url } = props;

    return (
        <li>
            <NavLink className="footer__link" to={url}>
                {name}
            </NavLink>
        </li>
    );
};

export const Footer = () => (
    <footer className="footer d-print-none bg-dark py-4 text-center text-lg-start">
        <div className="container">
            <div className="d-flex flex-column flex-lg-row gap-4 gap-lg-6 align-items-start">
                <div className="vstack gap-2 gap-lg-3">
                    <h2 className="h6 text-nowrap">Help</h2>

                    <ul className="list-unstyled mb-0">
                        <FooterLink url={RouteList.Videos} name="Videos" />
                        <FooterLink url={RouteList.Faqs} name="FAQs" />
                        <FooterLink url={RouteList.Resources} name="Resources" />
                        <FooterLink url={RouteList.Events} name="Events" />
                    </ul>
                </div>

                <div className="vstack gap-2 gap-lg-3">
                    <h2 className="h6 text-nowrap">About</h2>

                    <ul className="list-unstyled mb-0">
                        <FooterLink url={RouteList.Contact} name="Contact us" />
                        <FooterLink url={RouteList.Newsletter} name="Newsletter" />
                        <FooterLink url={RouteList.ReferAFriend} name="Refer a friend" />
                        <FooterLink url={RouteList.Team} name="Team" />
                        <FooterLink url={RouteList.Friends} name="Friends" />
                    </ul>
                </div>

                <div className="vstack gap-2 gap-lg-3">
                    <h2 className="h6 text-nowrap">Legal</h2>

                    <ul className="list-unstyled mb-0">
                        <FooterLink url={RouteList.Terms} name="Terms of use" />
                        <FooterLink url={RouteList.Privacy} name="Privacy" />
                        <FooterLink url={RouteList.Safety} name="Safety" />
                        <FooterLink url={RouteList.Permissions} name="Permissions" />
                    </ul>
                </div>

                <div className="vstack gap-2 gap-lg-3">
                    <h2 className="h6">Connect with us</h2>

                    <ul className="list-unstyled hstack gap-2 mb-0 justify-content-center justify-content-lg-start">
                        <li>
                            <a
                                href="https://www.facebook.com/people/Frankenstories/100083185037954/"
                                target="_blank"
                                title="Facebook"
                                rel="noreferrer"
                                className="btn btn-facebook btn-circle text-white"
                            >
                                <span className="fab fa-facebook"></span>
                            </a>
                        </li>
                        <li>
                            <a
                                href={Constants.instagramUrl}
                                target="_blank"
                                rel="noreferrer"
                                title="Instagram"
                                className="btn btn-instagram btn-circle text-white"
                            >
                                <span className="fab fa-instagram"></span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.linkedin.com/company/writelike"
                                target="_blank"
                                rel="noreferrer"
                                title="LinkedIn"
                                className="btn btn-linkedin btn-circle text-white"
                            >
                                <span className="fab fa-linkedin"></span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.reddit.com/r/Frankenstories/"
                                target="_blank"
                                rel="noreferrer"
                                title="Reddit"
                                className="btn btn-reddit btn-circle text-white"
                            >
                                <span className="fab fa-reddit"></span>
                            </a>
                        </li>
                    </ul>

                    <div className="small">
                        We acknowledge the Traditional Owners of country throughout Australia and recognise their
                        continuing connection to land, waters and culture. We pay our respects to their Elders past and
                        present.
                    </div>
                </div>
            </div>
        </div>
    </footer>
);
