import clsx from "clsx";
import { Decision } from "components/Decision";
import { Footer } from "components/Footer";
import { Navbar } from "components/Navbar";
import { GlobalContext } from "contexts/GlobalContext";
import { useScrollToTop } from "hooks/useScrollToTop";
import { ReactNode, useEffect, useContext, useState, PropsWithChildren } from "react";
import { Link, useHistory } from "react-router-dom";
import { RouteList } from "Routes";

type AuthType = "Public" | "Teachers" | "AuthRequired" | "AuthBlocked" | "NotStudents";
type ViewType = "LoginRequired" | "TeacherAccessRequired" | "Content";

export const pageLoadMessageKey = "page-load-message";

export const setPageLoadMessage = (message: string) => localStorage.setItem(pageLoadMessageKey, message);

export const Page = (
    props: PropsWithChildren<{
        title: string;
        description: string;
        canonical: string;
        authType: AuthType;
        imageUrl?: string;
        subNav?: ReactNode;
        useContainer?: boolean;
        noIndex?: boolean;
    }>,
) => {
    const { children, title, description, canonical, subNav, authType, useContainer = true, noIndex } = props;

    const { isAuthenticated, userData, addNotification } = useContext(GlobalContext);

    useScrollToTop();

    const history = useHistory();

    // handle view type
    const [viewType, setViewType] = useState<ViewType>();

    useEffect(() => {
        if (authType === "AuthBlocked" && isAuthenticated) {
            history.push(RouteList.Home);
        } else if ((authType === "Teachers" || authType === "AuthRequired") && !isAuthenticated) {
            // auth required but user doesn't have it
            setViewType("LoginRequired");
        } else if (authType === "NotStudents" && userData?.isStudent) {
            history.push(RouteList.Home);
        } else if (authType === "Teachers" && userData?.isStudent) {
            // students just get redirected
            history.push(RouteList.Home);
        } else {
            setViewType("Content");
        }
    }, [authType, history, isAuthenticated, userData?.isStudent]);

    // set the title
    useEffect(() => {
        const titleElement = document.querySelector("title");
        // const ogTitleElement = document.querySelector("meta[property='og:title']");

        const siteName = "Frankenstories";

        if (titleElement) {
            // we only want to show the site name on home page
            const fullTitle = title.length
                ? `${title} - ${siteName}`
                : `${siteName} - A creative writing game for the whole class`;

            titleElement.textContent = fullTitle;
        }
    }, [title]);

    // set the description
    useEffect(() => {
        const descriptionElement = document.querySelector("meta[name='description']");
        const ogDescriptionElement = document.querySelector("meta[property='og:description']");

        if (descriptionElement) {
            descriptionElement.setAttribute("content", description ?? "");
        }

        if (ogDescriptionElement) {
            ogDescriptionElement.setAttribute("content", description ?? "");
        }
    }, [description]);

    // set canonical url
    useEffect(() => {
        const fullCanonical = `https://${window.location.host}${canonical}`;

        const canonicalElement = document.querySelector("link[rel='canonical']");
        const ogCanonicalElement = document.querySelector("meta[property='og:url']");

        if (canonicalElement) {
            canonicalElement.setAttribute("href", fullCanonical);
        }

        if (ogCanonicalElement) {
            ogCanonicalElement.setAttribute("content", fullCanonical ?? "");
        }
    }, [canonical]);

    // manage noindex tags
    useEffect(() => {
        const metaTag = document.querySelector("meta[name='robots'][content='noindex']");

        // add tag if required
        if (noIndex && !metaTag) {
            const newMetaTag = document.createElement("meta");
            newMetaTag.setAttribute("name", "robots");
            newMetaTag.setAttribute("content", "noindex");
            document.head.appendChild(newMetaTag);
        } else if (!noIndex && metaTag) {
            document.head.removeChild(metaTag);
        }
    }, [noIndex]);

    // handle page load message
    useEffect(() => {
        const pageLoadMessage = localStorage.getItem(pageLoadMessageKey);

        if (pageLoadMessage) {
            addNotification({
                type: "success",
                title: pageLoadMessage,
            });

            localStorage.removeItem(pageLoadMessageKey);
        }
    }, [addNotification]);

    return (
        <div className="d-flex flex-grow-1 flex-column gap-4 gap-lg-5 h-100">
            <div className="d-print-none">
                <Navbar />
                {subNav}
            </div>

            <div className={clsx(useContainer && "container", "d-flex flex-grow-1 position-relative")}>
                <div className="d-flex flex-grow-1 flex-column position-relative w-100">
                    {viewType === "LoginRequired" ? (
                        <div className="container-mini">
                            <Decision
                                text="Login required"
                                description="Log in or register and come back"
                                hideGoHome={true}
                                actionElement={
                                    <Link to={RouteList.Login} className="btn btn-primary">
                                        Login
                                    </Link>
                                }
                            />
                        </div>
                    ) : viewType === "TeacherAccessRequired" ? (
                        <div className="container-mini vstack gap-3">
                            <Decision
                                text="Teacher account required"
                                description="You need a teacher account to access this page"
                            />
                        </div>
                    ) : (
                        children
                    )}
                </div>
            </div>

            <Footer />
        </div>
    );
};
