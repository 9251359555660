import { IdCommand } from "commands/IdCommand";
import { AddStudentsCommand } from "commands/users/AddStudentsCommand";
import { CreateGroupCommand } from "commands/users/CreateGroupCommand";
import { RemoveStudentsFromGroupCommand } from "commands/users/RemoveStudentsFromGroupCommand";
import { UpdateGroupCommand } from "commands/users/UpdateGroupCommand";
import { UpdateStudentDetailsCommand } from "commands/users/UpdateStudentDetailsCommand";
import { CodeResult } from "results/CodeResult";
import { GetTeacherGroupRemoveStudentsResult } from "results/frankenstories/teachers/GetTeacherGroupRemoveStudentsResult";
import { GetTeacherGroupResult } from "results/frankenstories/teachers/GetTeacherGroupResult";
import { GetTeacherStudentResult } from "results/frankenstories/teachers/GetTeacherStudentResult";
import { GetTeacherStudentsResult } from "results/frankenstories/teachers/GetTeacherStudentsResult";
import { AddStudentsResult } from "results/users/AddStudentsResult";
import { CreateGroupResult } from "results/users/CreateGroupResult";
import { useApiService } from "./ApiService";
import { GetClassesClassGamesResult } from "results/classes/GetClassesClassGamesResult";
import { GetClassesClassResult } from "results/classes/GetClassesClassResult";
import { GetClassesClassStudentRepliesResult } from "results/classes/GetClassesClassStudentRepliesResult";
import { GetClassesClassStudentResult } from "results/classes/GetClassesClassStudentResult";
import { GetClassesResult } from "results/classes/GetClassesResult";
import { CodeCommand } from "commands/CodeCommand";
import { IdResult } from "results/IdResult";
import { GetClassesClassShowcaseResult } from "results/classes/GetClassesClassShowcaseResult";

export const ClassesApiService = () => {
    const { get, post } = useApiService({ controller: "/classes" });

    return {
        joinGroup: (command: CodeCommand) => post<IdResult>("/join-group", command),
        getGroup: (id: number) => get<GetTeacherGroupResult>(`/group/${id}`),
        getGroupRemoveStudents: (id: number) =>
            get<GetTeacherGroupRemoveStudentsResult>(`/group/remove-students/${id}`),
        getTeacherStudents: () => get<GetTeacherStudentsResult>("/students"),
        getTeacherStudent: (id: number) => get<GetTeacherStudentResult>(`/student/${id}`),
        regenerateGroupCode: (command: IdCommand) => post<CodeResult>("/group/regenerate-code", command),
        createGroup: (command: CreateGroupCommand) => post<CreateGroupResult>("/groups/create", command),
        updateGroup: (command: UpdateGroupCommand) => post("/groups/update", command),
        deleteGroup: (id: number) => post(`/groups/delete/${id}`),
        deleteStudent: (id: number) => post(`/students/delete/${id}`),
        removeStudentsFromGroup: (command: RemoveStudentsFromGroupCommand) => post("/groups/remove-students", command),
        updateStudentDetails: (command: UpdateStudentDetailsCommand) =>
            post("/students/update-student-details", command),
        addTeacherStudents: (command: AddStudentsCommand) => post<AddStudentsResult>("/students/add", command),
        getClasses: () => get<GetClassesResult>("/classes"),
        getClass: (id: number) => get<GetClassesClassResult>(`/classes/${id}`),
        getClassStudent: (classId: number, studentId: number) =>
            get<GetClassesClassStudentResult>(`/classes/${classId}/${studentId}`),
        getClassStudentReplies: (classId: number, studentId: number) =>
            get<GetClassesClassStudentRepliesResult>(`/classes/${classId}/${studentId}/replies`),
        getClassShowcase: (classId: number) => get<GetClassesClassShowcaseResult>(`/classes/${classId}/showcase`),
        getClassGames: (classId: number) => get<GetClassesClassGamesResult>(`/classes/${classId}/games`),
    };
};
