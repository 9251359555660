import Icon from "assets/img/icon.svg";
import { RouteList } from "Routes";
import { NavLink } from "react-router-dom";
import { Fragment, useContext } from "react";
import { GlobalContext } from "contexts/GlobalContext";
import { ReactComponent as SiteName } from "assets/img/site-name.svg";

export const Navbar = () => {
    const { isAuthenticated, userData, globalData } = useContext(GlobalContext);

    return (
        <nav className="navbar text-bg-dark navbar-expand-lg sticky-top">
            <div className="container flex-row">
                <NavLink className="d-flex align-items-center" to={RouteList.Home} exact={true}>
                    <img alt="Frankenstories" className="me-2" src={Icon} style={{ height: 26, maxWidth: "100%" }} />

                    <SiteName style={{ height: 16, width: 125, maxWidth: "100%" }} className="me-2 svg-link" />
                </NavLink>

                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbar"
                    aria-label="Toggle navbar"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbar">
                    <ul className="navbar-nav gap-lg-3 me-auto ms-lg-3">
                        <li className="nav-item">
                            <NavLink className="nav-link" to={RouteList.Games}>
                                <span className="fal fa-gamepad"></span>
                                <span className="nav-link__text">Games</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={RouteList.Discover}>
                                <span className="fal fa-compass"></span>
                                <span className="nav-link__text">Discover</span>
                            </NavLink>
                        </li>

                        {!userData?.isStudent && (
                            <Fragment>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to={RouteList.Pricing}>
                                        <span className="fal fa-rocket"></span>
                                        <span className="nav-link__text">Pricing</span>
                                    </NavLink>
                                </li>
                            </Fragment>
                        )}
                    </ul>

                    <ul className="navbar-nav gap-lg-3">
                        {isAuthenticated ? (
                            <Fragment>
                                <li>
                                    <NavLink className="nav-link" to={RouteList.Classes}>
                                        <span className="fal fa-users"></span>
                                        <span className="nav-link__text">Classes</span>
                                    </NavLink>
                                </li>

                                <li className="nav-item position-relative">
                                    <NavLink to={RouteList.Profile} className="nav-link">
                                        <span className="fal fa-user"></span>
                                        <span className="nav-link__text">Profile</span>
                                    </NavLink>

                                    {isAuthenticated && !userData?.isStudent && globalData?.isSubscribed === false && (
                                        <span
                                            title="Expired subscription"
                                            className="z-1 position-absolute top-100 start-50 translate-middle badge rounded-pill bg-danger"
                                        >
                                            &nbsp;
                                        </span>
                                    )}
                                </li>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <li className="nav-item">
                                    <NavLink to={RouteList.Login} className="nav-link">
                                        <span className="fal fa-right-to-bracket"></span>
                                        <span className="nav-link__text">Log in</span>
                                    </NavLink>
                                </li>

                                <li className="nav-item dropdown" id="nav-register">
                                    <NavLink to={RouteList.Signup} className="nav-link">
                                        <span className="fal fa-user-plus"></span>
                                        <span className="nav-link__text">Sign up</span>
                                    </NavLink>
                                </li>
                            </Fragment>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
};
