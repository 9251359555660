import { RouteList } from "Routes";

export const Redirects: Map<string, string> = new Map([
    ["/aate", "https://docs.google.com/forms/d/e/1FAIpQLSclNPUFoMLBEG8NECqKS1fMVunpD0aKkZ69ZfPVczEzaKOWVg/viewform"],
    [
        "/comp-entry",
        "https://docs.google.com/forms/d/e/1FAIpQLSfoId0y3JXtp6rbCAOlRdKKUomFa8sjyJmbv25ZG1XVtjC-xw/viewform?usp=sf_link",
    ],
    ["/subscriptions/about", RouteList.Pricing],
    ["/subscriptions/refer-a-friend", RouteList.ReferAFriend],
    ["/subscriptions/purchase", RouteList.Pricing],
    ["/register", RouteList.Signup],
    ["/about", RouteList.Pricing],
    ["/prompts", RouteList.Discover],
    ["/prompts/browse", RouteList.Discover],
    ["/help/videos", RouteList.Videos],
    ["/help/faqs", RouteList.Faqs],
    ["/help/resources", RouteList.Resources],
    ["/help/contact-us", RouteList.Contact],
    ["/help/newsletter", RouteList.Newsletter],
    ["/help/events", RouteList.Events],
    ["/help/about-us", RouteList.Team],
    ["/help/terms", RouteList.Terms],
    ["/help/privacy", RouteList.Privacy],
    ["/help/permissions", RouteList.Permissions],
    ["/help/safety", RouteList.Safety],
    ["/profile/refer-a-friend", RouteList.ProfileReferralCode],
]);
