import { CodeResult } from "results/CodeResult";
import { GetProfileMyAvatarsResult } from "results/users/GetProfileMyAvatarsResult";
import { GetProfileMySchoolResult } from "results/users/GetProfileMySchoolResult";
import { GetProfileReferAFriendResult } from "results/users/GetProfileReferAFriendResult";
import { GetUnlockedAvatarsResult } from "results/users/GetUnlockedAvatarsResult";
import { ProfileResult } from "results/users/ProfileResult";
import { useApiService } from "services/ApiService";

export const UsersApiService = () => {
    const { get, post } = useApiService({ controller: "/users" });

    return {
        getProfile: () => get<ProfileResult>("/profile"),
        getProfileMyAvatars: () => get<GetProfileMyAvatarsResult>("/my-avatars"),
        selectProfileAvatar: (avatarId?: number) => post("/select-avatar", { avatarId }),
        getUnlockedAvatars: () => get<GetUnlockedAvatarsResult>("/unlocked-avatars"),
        getReferAFriend: () => get<GetProfileReferAFriendResult>("/refer-a-friend"),
        generateReferrerCode: () => post<CodeResult>("/generate-referrer-code"),
        getMySchool: () => get<GetProfileMySchoolResult>("/my-school"),
    };
};
